import React from "react";
import github from "../icons/contacts/github.png";
import linkedin from "../icons/contacts/linkedin.png";
import mail from "../icons/contacts/mail.png";
import resume from "../icons/contacts/resume.png";
import resumePage from "../resume.pdf";
import "../css/components/Bookmarks.css";

function Bookmarks() {
  const contactsArray = [
    {
      link: "https://github.com/ChicoRao",
      icon: github,
      name: "Github",
    },
    {
      link: "https://linkedin.com/in/rico-chao",
      icon: linkedin,
      name: "Linkedin",
    },
    {
      link: "mailto:ricochao114@gmail.com",
      icon: mail,
      name: "Email",
    },
    {
      link: resumePage,
      icon: resume,
      name: "Resume",
    },
  ];

  return (
    <ul>
      {contactsArray.map((contact) => {
        return (
          <li>
            <a href={contact.link} target="_blank">
              <img src={contact.icon} alt={contact.name} />
              <span>{` ${contact.name}`}</span>
            </a>
          </li>
        );
      })}
    </ul>
  );
}

export default Bookmarks;
