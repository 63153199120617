import "../css/pages/Home.css";

const Home = () => {
  const style = {
    color: "rgb(0, 110, 255)",
  };

  return (
    <div className="home-content">
      <h1>Hi, I am Rico!</h1>
      <h2>
        I enjoy <span style={style}>building things</span> and{" "}
        <span style={style}>solving problems</span>.
      </h2>
      {/* <p>
        I am a Software Engineer{" "}
        <strong style={style}>seeking for full time opportunities.</strong>
      </p> */}
      <p>
        I am currently working as a{" "}
        <strong style={style}>Full-Stack Developer</strong> at Let&apos;s Pair
        Educations.
      </p>
      <a href="https://linkedin.com/in/rico-chao">
        <button>Connect with me!</button>
      </a>
    </div>
  );
};

export default Home;
