import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Tabs from "./components/Tabs";
import BookmarkContainer from "./components/BookmarkContainer";
import Home from "./pages/Home";
import Experiences from "./pages/Experiences";
import Projects from "./pages/Projects";
import Other from "./pages/Other";
import home from "./icons/home.png";
import experiences from "./icons/experiences.png";
import projects from "./icons/projects.png";
import bitmoji from "./icons/bitmoji.png";
import "./css/App.css";

const tabArray = [
  {
    id: "home",
    name: "Home",
    icon: home,
    isActive: true,
  },
  {
    id: "experiences",
    name: "Experiences",
    icon: experiences,
    isActive: false,
  },
  {
    id: "projects",
    name: "Projects",
    icon: projects,
    isActive: false,
  },
  // {
  //     id: "other",
  //     name: "Other",
  //     icon: bitmoji,
  //     isActive: false
  // }
];

function App() {
  const currentTab =
    window.location.pathname === "/"
      ? "home"
      : window.location.pathname.slice(1);

  const [activeTab, setTab] = useState(currentTab);

  //When application first starts, or after page refresh
  tabArray.forEach((tab) => {
    tab.isActive = tab.id === currentTab;
  });

  console.log(tabArray);

  // Dragging functionaility
  const [position, setPosition] = useState({ top: "50%", left: "50%" });
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null); //Create a ref for the container

  useEffect(() => {
    const centerDiv = () => {
      setPosition({ top: "50%", left: "50%" });
    };
    centerDiv();
  }, []);

  const handleMouseDown = (e) => {
    e.preventDefault();

    // const boundingRect = e.currentTarget.getBoundingClientRect();
    const boundingRect = containerRef.current.getBoundingClientRect();

    // Adjust for the translate
    const elementCenterX = boundingRect.left + boundingRect.width / 2;
    const elementCenterY = boundingRect.top + boundingRect.height / 2;

    setOffset({
      x: e.clientX - elementCenterX,
      y: e.clientY - elementCenterY,
    });
    setDragging(true);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!dragging) return;

      // const containerHeight = containerRef.current.offsetHeight;
      // const containerWidth = containerRef.current.offsetWidth;

      // const newTop = Math.max(0, Math.min(window.innerHeight - containerHeight, e.clientY - offset.y));
      // const newLeft = Math.max(0, Math.min(window.innerWidth - containerWidth, e.clientX - offset.x));

      // console.log("newTop: ", newTop);
      // console.log("newLeft: ", newLeft);

      // setPosition({
      //   top: `${newTop}px`,
      //   left: `${newLeft}px`,
      // });

      setPosition({
        top: `${e.clientY - offset.y}px`,
        left: `${e.clientX - offset.x}px`,
      });
    };

    const handleMouseUp = () => {
      setDragging(false);
    };

    if (dragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    // Cleanup listeners on component unmount
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging, offset]);

  const switchTabs = (event, selectedTab) => {
    setTab(selectedTab);

    //Setting all the other tabs to inActive
    tabArray.forEach((tab) => {
      tab.isActive = tab.id === selectedTab;
    });
  };

  const whiteBackgroundStyle = {
    backgroundColor: "white",
  };

  const experiencesBackgroundStyle = {
    backgroundColor: "#b3ffe6",
  };

  const projectsBackgroundStyle = {
    backgroundColor: "#ccccff",
  };

  const changeStyle = (selectedTab) => {
    switch (selectedTab) {
      case "experiences":
        return experiencesBackgroundStyle;
      case "projects":
        return projectsBackgroundStyle;
      default:
        return whiteBackgroundStyle;
    }
  };

  return (
    <BrowserRouter>
      <div
        className="container"
        ref={containerRef}
        style={{
          top: position.top,
          left: position.left,
          cursor: dragging ? "grabbing" : "grab",
        }}
        onMouseDown={handleMouseDown}
      >
        <div className="three-buttons">
          <span
            className="circle"
            style={{ backgroundColor: "#66ff99" }}
          ></span>
          <span
            className="circle"
            style={{ backgroundColor: "#f5d189" }}
          ></span>
          <span
            className="circle"
            style={{ backgroundColor: "#f08e67" }}
          ></span>
        </div>
        <Tabs tabArray={tabArray} switchTabs={switchTabs} />
        <BookmarkContainer selectedTab={activeTab} />
        <div className="content" style={changeStyle(activeTab)}>
          <Routes>
            {/* <Redirect to="/home" /> */}
            <Route
              path="/"
              element={<Navigate replace to={`/${activeTab}`} />}
            />
            <Route path="/home" element={<Home />} />
            <Route path="/experiences" element={<Experiences />} />
            <Route path="/projects" element={<Projects />} />
            {/* <Route path="/other" element={ <Other/> } /> */}
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
